// @flow
import React from 'react';
import { Layout } from 'src/components';
import { SITE_METADATA } from 'src/constants';
import TopText from 'src/components/Landing/TopText';
import NoPayment from 'src/components/Landing/NoPayment';
import UseInstallments from 'src/components/Landing/UseInstallments';
import VerifyIdentity from 'src/components/Landing/VerifyIdentity';
import styles from '../styles/pages/landing.module.scss';
import ConversionTop6Pay from '../components/Landing/ConversionTop6Pay';
import Point from '../components/Landing/Point';
import Step from '../components/Landing/Step';
import Can from '../components/Landing/Can';
import ConversionBottom6Pay from '../components/Landing/ConversionBottom6Pay';
import Plus6Pay from '../components/Landing/Plus6Pay';
import Footer6Pay from '../components/Landing/Footer6Pay';
import TopBanner from '../components/Landing/TopBanner';

const SEOProps = {
  title: SITE_METADATA.landing_6pay_ekyc.title,
  description: SITE_METADATA.landing_6pay_ekyc.description,
};

const Landing6PayEKYC = () => {
  return (
    <Layout SEOProps={SEOProps} hasNoFooter hasNoHeader>
      <div className={styles.landing}>
        <TopBanner />
        <ConversionTop6Pay />
        <TopText />
        <VerifyIdentity />
        <Point />
        <Step />
        <NoPayment />
        <UseInstallments />
        <Can />
        <Plus6Pay />
        <ConversionBottom6Pay />
        <Footer6Pay />
      </div>
    </Layout>
  );
};

export default Landing6PayEKYC;
